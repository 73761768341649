.questionaire-wrapper {
  background: linear-gradient(
    108deg,
    #062c6b 0%,
    #05183a 22%,
    #041129 46%,
    #1a1633 77%,
    #441f47 100%
  );
  .questionaire-header {
    padding-top: 18vh;
    padding-bottom: 18vh;
    color: white;
    h2 {
      font-size: 60px;
    }
    p {
      font-size: 17px;
    }
  }
  .add_txt {
    border-radius: 24px;
    text-align: center;
    // width: 40px;
    border-width: 1px;
    font-weight: bold;
    border-color: "#041129";
    border-style: solid;
    padding: 4px 20px 4px 20px;
  }
  label {
    font-size: 14px;
    font-weight: bold;
  }
  form {
    background: #f9f9fb;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  }
  .flexed-checkbox {
    &.ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }
  }

  .iconify {
    margin-left: 20px;
  }
  .iconify-left {
    margin-right: 20px;
  }
  .reduce-top {
    margin-top: -40px;
  }

  .ant-checkbox-group-item,
  .ant-radio-wrapper {
    margin-right: 15px;
    cursor: pointer;
    .ant-checkbox,
    .ant-radio {
      margin-right: 8px;
    }
  }
  .import-box {
    background: #f5f5f7;
    border: 2px dashed #eb5424;
    border-radius: 10px;
    color: #979797;
    width: 660px;
    max-width: 100%;
    height: 164px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: auto;
    cursor: pointer;
    overflow: scroll;
    @media (max-width: 700px) {
      width: 100%;
    }
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 13px;
      }
    }
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: #fff;
    border-color: #ff4d4f;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    font-size: 12px;
  }
}
.text-red {
  color: red;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}




.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #096dd9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #40a9ff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}