.login-wrapper {
  font-size: 14px;
  min-height: 100vh;
  overflow: hidden;
  p {
    color: #484848;
  }

  .text-red {
    color: #ff3057 !important;
  }
  .login-content {
    padding: 40px 45px;
    position: relative;
    overflow: scroll;
    height: 100vh;
    // background:url('../../assets/img/bg-love.png') no-repeat center;
    // background-size: cover;
    .logo img {
      max-width: 100%;
      width: 150px;
      filter: brightness(0);
      @media (max-width: 992px) {
        display: none;
      }
    }
    .login-header {
      padding: 75px 0 50px;
      font-family: "Gotham-rg";
      h3 {
        font-weight: 700;
      }
      p {
        margin: 0;
        color: #9fa2b0;
      }
    }
    .footer-content {
      position: static;
    }

    @media (min-width: 320px) and (max-width: 768px) {
      padding: 20px 15px 20px;
      height: auto;

      .logo {
        text-align: center;
      }
      .login-header {
        padding: 40px 0;
        padding-top: 13vh;
      }
      .login-form .form-control {
        width: 100%;
      }
    }

    @media (min-width: 1024px) {
      .footer-content {
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
      }
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      .login-content {
        padding: 40px 15px;
      }
    }
  }
  .right-section {
    height: 100vh;
    &.register-bg {
      background: linear-gradient(
          180deg,
          rgba(98, 6, 41, 0.58) 0%,
          rgba(248, 84, 11, 0.24) 72.4%
        ),
        url("../../assets/img/bg.png") no-repeat center center;
      background-size: cover;
    }
    &.login-bg {
      background: linear-gradient(
          180deg,
          rgba(98, 6, 41, 0.58) 0%,
          rgba(248, 84, 11, 0.24) 72.4%
        ),
        url("../../assets/img/bg.png") no-repeat center center;
      background-size: cover;
    }
  }

  @media (min-width: 770px) {
    .right-section {
      display: none;
      // flex-direction: column;
    }
  }
  .check-container {
    .checkmark {
      top: 11px;
      left: 13px;
    }
    input:checked ~ .checkmark {
      background-color: pink;
    }
  }
  .btn {
    box-shadow: 2px 2px 2px 2px rgba(159, 162, 176, 0.3);
    padding: 0.5rem 2.8rem !important;
    &:hover {
      transform: translateY(-2px);
    }
  }
  .btn-red {
    //   background: linear-gradient(90deg, rgba(220,68,127,1) 0%, rgba(248,123,67,1) 99%);
    background: #eb5424;
  }
  .btn-outline-red {
    color: linear-gradient(
      90deg,
      rgba(220, 68, 127, 1) 0%,
      rgba(248, 123, 67, 1) 99%
    );
    border: solid 0;
    border-color: linear-gradient(
      90deg,
      rgba(220, 68, 127, 1) 0%,
      rgba(248, 123, 67, 1) 99%
    );
  }
  .remember {
    color: #9395ac;
  }
  .text-grey {
    color: #9fa2b0;
  }

  .modal-title {
    color: #203050;
  }
  .modal-body {
    padding: 1.5rem 2.3rem;
    @media (max-width: 770px) {
      padding: 1.5rem;
    }
  }
  .small-footer {
    p {
      color: white;
      a {
        color: white;
      }
    }
  }
}
