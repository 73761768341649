@import "~react-vis/dist/style";
:root {
  --primaryColor: #eb5424;
  --altPrimaryColor: #050d21;
  --fontRadikalBold: "Radikal-bd";
  --fontRadikal: "Radikal-rg";
  --fontAllianceExtra: "Alliance-ExtraBold";
}

.dashboard-wrapper {
  background: #f3f4f5;
  .dash-count-box {
    background: #5555f5;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    height: 155px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .project-tab-wrapper {
    .nav-pills {
      .nav-link {
        color: #333333;
        opacity: 0.6;
        font-weight: bold;
        // font-family: "SF-mono";
      }
      .nav-link.active,
      .show > .nav-link {
        opacity: 1;
        background-color: transparent;
      }
    }
    table {
      padding: 0 !important;
      border-spacing: 0px 11px !important;
      background-color: transparent !important;
      border-collapse: separate;
      tr {
        box-shadow: 0 2px 4px rgba(#525252, 0.12);
        td {
          font-size: 15px;
          max-width: 300px;
        }
      }
      thead {
        background: #5555f5;
        border-radius: 8px;
        color: white;
        font-size: 12px;
      }
      .project-img {
        width: 50px;
      }
    }
  }
  .view_btn {
    background: #5555f5;
    border-radius: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    color: #ffffff;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    border: none;
    outline: none;
    &:hover {
      color: white;
    }
  }
  .completed-class {
    // fill: #5555f5 !important;
    // stroke: #5555f5 !important;
  }
  .progress-class {
    fill: #ef943b !important;
    stroke: #ef943b !important;
  }
  .not_started-class {
    fill: #968e8e !important;
    stroke: #968e8e !important;
  }
  .project-detail-wrapper {
    .label {
      color: #a8a8a8;
    }
    .value {
      color: #505050;
      line-height: 1.2;
    }
  }

  h3 {
    font-family: var(--fontAllianceExtra);
    color: var(--altPrimaryColor);
    font-size: 24px;
  }
}

#projects {
  ul {
    list-style: none;
  }
  .shadowCard {
    width: 60%;
    height: 100px;
    background: var(--altPrimaryColor);
    border: 1px solid #8193b2;
    // border-width: 1px;
    border-color: rgba(160, 160, 255, 0.2);
    border-radius: 14px;
    position: absolute;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
      -5px -5px 35px rgba(254, 214, 124, 0.1);
    left: 50%;
    transform: translate(-50%, 0);
    p {
      font-size: 14px;
      padding: 0px 10px 5px 10px;
      line-height: 3rem;
      letter-spacing: 1px;
      color: #8193b2;
    }
  }

  .shadowCard2 {
    width: 69%;
    height: 100px;
    margin-top: 35px;
    // background-color: "red";
    background: var(--altPrimaryColor);
    border: 1px solid #8193b2;
    // border-width: 1px;
    border-color: rgba(160, 160, 255, 0.2);
    border-radius: 14px;
    position: absolute;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
      -5px -5px 35px rgba(254, 214, 124, 0.1);
    left: 50%;
    transform: translate(-50%, 0);
    p {
      font-size: 14px;
      padding: 0px 10px 5px 10px;
      line-height: 3rem;
      letter-spacing: 1px;
      color: #8193b2;
    }
  }
  .card {
    display: flex;
    width: 90%;
    margin-top: 75px;
    background: var(--altPrimaryColor);
    border-radius: 14px;
    top: 0px;
    transition: 0.3s all ease-in-out;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.2),
      -5px -5px 35px rgba(254, 214, 124, 0.3);
    &:hover {
      // top: -80px;

      // margin-top: -10px;
    }
    p {
      font-size: 14px;
      padding: 0px 10px 5px 10px;
      line-height: 3rem;
      letter-spacing: 1px;
      color: #8193b2;
    }
    .status {
      padding: 2px 17px 0px 17px;
      margin-left: 34px;
      margin-bottom: 18px;
      border-radius: 12px;
      border-width: 1px;
      border-style: solid;
      color: #8193b2;
      font-size: 12px;
    }
    .header1 {
      padding: 14px 20px 0px 14px;
      background: linear-gradient(to right, #ff8272 0%, #fed67c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header2 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #c24fac 0%, #5575e3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header3 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header4 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #330867 0%, #ff8272 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header {
      padding: 14px 20px 10px 14px;
      .ruler {
        height: 1px;
        background-color: #8193b2;
      }
    }
    .content {
      padding: 0px 16px 24px 16px;
      .info {
        width: 100%;
        padding: 4px 12px 0 12px;
        // background-color: #30cfd0;
        font-size: 14px;
        line-height: 1rem;
        letter-spacing: 1px;
        color: #8193b2;
      }
    }

    &__button {
      padding: 10px;
      border-radius: 50px;
      background: #ff8272;
      color: white;
      font-weight: bold;
      cursor: pointer;
      border: none;
      // margin: 20px auto;
    }

    .btn-price {
      background: var(--primaryColor) !important;
      color: #fff !important;
      border-radius: 24px !important;
      padding: 0.6vh 3.5vh 0.6vh 3.5vh !important;
      margin-top: 20px;
      font-size: 1.7vh;
    }
  }
}
