@font-face {
  font-family: "Alliance-ExtraBold";
  src: url("../../assets/font/AllianceExtraBold.ttf");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("../../assets/font/Ubuntu-Light.ttf");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("../../assets/font/Ubuntu-Regular.ttf");
}

:root {
  --primaryColor: #eb5424;
  --altPrimaryColor: #050d21;
  --fontRadikalBold: "Radikal-bd";
  --fontRadikal: "Radikal-rg";
  --fontAllianceExtra: "Alliance-ExtraBold";
  --fontUbuntuLT: "Ubuntu-Light";
  --fontUbuntuRG: "Ubuntu-Regular";
}

.admin-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(
    108deg,
    rgba(6, 44, 107, 1) 0%,
    rgba(5, 24, 58, 1) 22%,
    rgba(4, 17, 41, 1) 46%,
    rgba(26, 22, 51, 1) 77%,
    rgba(68, 31, 71, 1) 100%
  );

  .content {
    padding-top: 15vh;
    .cl-text {
      background: linear-gradient(to right, #ff8272 0%, #fed67c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .wh-text {
      color: white;
    }
    .base-text {
      color: var(--primaryColor);
    }
  }
  .fill {
    flex-grow: 1;
  }

  .status {
    padding: 2px 17px 0px 17px;
    // margin-left: 34px;
    margin-right: 24px;
    margin-bottom: 18px;
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    color: #8193b2;
    font-size: 12px;
  }
}

li {
  list-style: none;
}

footer {
  margin-top: auto;
}

footer span {
  list-style: none;
  color: #cfcfdf;
  font-size: 12px;
}

.curve-bg {
  // background-image: url("./assets/img/polygon.png");
  min-height: 110vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 150%;
}

.text-primary-color {
  color: var(--primaryColor);
}

.text-black {
  color: #1a1d22 !important;
}

.text-bold {
  font-weight: 600;
  // font-family: "proxima-bold";
}

.mt-10 {
  margin-top: 10rem;
}

@media (max-width: 770px) {
  .sm-mt {
    margin-top: 20px !important;
  }

  .sm-justify-content-center {
    justify-content: center;
  }

  .banner-text {
    position: relative !important;
  }
  .img-text {
    height: auto;
    width: 80px;
  }

  .sm-mt-4 {
    margin-top: 4rem !important;
  }

  .sm-w-100 {
    width: 100% !important;
  }
}

@media (min-width: 771px) {
  .banner-text {
    position: absolute !important;
    z-index: 3;
  }

  .img-text {
    height: auto;
    width: 80px;
  }

  .nurse-img img {
    width: 480px;
  }

  .lg-dnone {
    display: none;
  }
}

.title {
  font-size: 24px;
}

.bg-black {
  background-color: black;
}
.navbar-icon {
  height: 24px;
  width: auto;
  margin-top: 16px;
}

.btn {
  // padding: 0.7rem 0.75rem !important;
  opacity: 1 !important;
}

input:focus,
.btn:active,
.btn:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.font14 {
  font-size: 14px !important;
}

.font18 {
  font-size: 18px;
}

.bg-red {
  background-color: var(--primaryColor);
}

.bg-decline {
  background-color: #d32f2f;
}

.txt-darkgrey {
  color: #3b3c3e;
}

.bg-primary-color {
  background-color: #4caf50;
}

.bg-green {
  background-color: #3dc498;
}

.bg-red:hover,
.bg-red:active {
  background-color: var(--altPrimaryColor);
}

.font20 {
  font-size: 20px !important;
}

.font24 {
  font-size: 24px;
}

.font12 {
  font-size: 12px;
}

.header-text {
  font-size: 36px;
  font-weight: 600;
  color: white;
}

.font-light {
  font-weight: lighter;
}

a {
  color: white;
  text-decoration: none;
  opacity: 0.8;
  transition: transform opacity 0.3s;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  color: white;
}

.p-relative {
  position: relative;
}

.text-light {
  color: #cfcfdf !important;
}

.text-normal {
  font-weight: normal !important;
}

.common-input {
  border: #cfcfdf solid 0.9px !important;
  padding: 1.375rem 0.75rem !important;
}

select {
  padding: 1.375rem 0.75rem !important;
}

select,
select option {
  font-size: 14px !important;
  color: #cfcfdf !important;
}

input::placeholder {
  font-size: 14px;
  color: #cfcfdf !important;
  /* Firefox */
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  color: #cfcfdf !important;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
  color: #cfcfdf !important;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
  color: #cfcfdf !important;
}

input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
  color: #cfcfdf !important;
}

.input-icon {
  position: absolute;
  right: 18px;
  top: 11px;
}

.text-grey {
  color: #7188a4 !important;
}

.text-dark-grey {
  color: #404b58 !important;
}

.search-input {
  width: 85%;
  padding-left: 40px;
  border: none;
  background: transparent !important;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 1.5px;
}

.search-go {
  border: none;
  background: transparent;
  color: #cfcfdf;
}

.big-underlay:after {
  position: absolute;
  content: "";
  // top: 0;
  left: 200px;
  right: 0;
  height: 60vh;
  // background: #18191c;
  z-index: -1;
  border-bottom-left-radius: 100px;
}

@media (max-width: 770px) {
  .big-underlay:after {
    left: 0;
  }
}

.small-underlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 200px;
  right: 0;
  height: 40vh;
  // background: #18191c;
  z-index: -1;
  border-bottom-left-radius: 100px;
}

@media (max-width: 770px) {
  .small-underlay:after {
    left: 0;
  }
}

.nav-pills .nav-link {
  font-size: 12px;
  color: #7188a4;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: var(--primaryColor) !important;
  color: #f9efed !important;
}

.nav-pills .nav-link.active .small-line {
  padding-top: 3px;
  background: #f9efed;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 12px;
}

.font36 {
  font-size: 36px;
}

.font40 {
  font-size: 40px;
}

.font30 {
  font-size: 30px;
}

.line-height {
  line-height: 1 !important;
}

.mt-8vh {
  margin-top: 8vh;
}

.card {
  border: none;
  border-radius: 12px;
  // background-color: #F7EFEB;
  // -webkit-box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
  // -moz-box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
  // box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
}

.card-alt {
  border: none;
  margin-bottom: 24px;
  border-radius: 9px;

  background: var(--altPrimaryColor);
  border: 1px solid #8193b2;
  // border-width: 1px;
  border-color: rgba(160, 160, 255, 0.2);
  border-radius: 14px;
  box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
    -5px -5px 35px rgba(254, 214, 124, 0.1);
}

.bg-white {
  background: white !important;
}

.small-btn {
  padding: 5px 12px !important;
}

table thead tr .dropdown {
  border: solid 1px #170d42 !important;
  padding: 5px 22px !important;
  border-radius: 7px;
}

.font9 {
  font-size: 9px;
}

.badge {
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 9px;
}

.red-badge {
  background: #a7002629;
  color: #a70026;
}

.green-badge {
  background: #15805629;
  color: #3dc498;
}

.pending-badge {
  background: #fce57738;
  color: #fcdf77;
}

.active-dot {
  height: 7px;
  width: 7px;
  background-color: #3dc498;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px 0 0;
}

.green-badge .active-dot {
  background-color: #3dc498;
}

.pending-badge .active-dot {
  background-color: #fcd577;
}

.red-badge .active-dot {
  background-color: #a70026;
}

.seated-badge {
  background: #a7002629;
  color: #a70026;
}

.confirm-badge {
  background: #15805629;
  color: #3dc498;
}

.confirm-badge .active-dot {
  background-color: #3dc498;
}

.pending-badge .active-dot {
  background-color: #fcd577;
}

.seated-badge .active-dot {
  background-color: #a70026;
}

button#dropdownMenuButton {
  background: transparent;
  border: none;
}

button#dropdownMenuButton:focus {
  border: none;
  box-shadow: none !important;
  outline: none;
}

.mt-25vh {
  margin-top: 25vh;
}

.table.dataTable tbody tr {
  background-color: transparent !important;
  -webkit-box-shadow: 0px 4px 5px -4px rgba(195, 195, 195, 1);
  -moz-box-shadow: 0px 4px 5px -4px rgba(195, 195, 195, 1);
  box-shadow: 0px 4px 5px -4px rgba(195, 195, 195, 1);
  cursor: pointer;
}

.border-gray {
  border: #7188a4 solid 0.9px;
}

.border-black {
  border: solid 1px #170d42 !important;
}

.text-underline {
  text-decoration: underline;
}

.bg-blue {
  background-color: #170d42 !important;
}

.br-5 {
  border-radius: 5px;
}

.hide {
  display: none !important;
}

/* The check-container */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -7px;
  left: 17px;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: #cfcfdf solid 0.7px;
}

tr td .checkmark {
  top: 0;
  left: 16px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #512da8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.table td,
.table th {
  vertical-align: middle;
  border: none !important;
}

table.dataTable tbody td {
  padding: 22px 18px !important;
}

td {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

table.dataTable.no-footer {
  border-bottom: none !important;
}

.subscription textarea.form-control,
.communications textarea.form-control {
  height: 255px !important;
  padding-top: 40px;
}

.subscription textarea.form-control,
.communications textarea.form-control:focus {
  box-shadow: none;
  outline: none;
}

.subscription textarea.form-control,
.communications textarea.form-control::placeholder {
  font-size: 14px;
  color: #7188a4;
}

textarea {
  resize: none;
}

.modal-header {
  border: none !important;
}

.modal .checkmark {
  top: 11px;
  left: 3px;
}

.text-cyan {
  color: #44b8c3;
}

.btn-view {
  color: white;
  padding: 0.1rem 1rem !important;
}

.btn-view:hover {
  color: white;
}

.btn-outline-blue {
  padding: 0.1rem 1rem !important;
  color: #512da8;
  border: #512da8 solid 1px;
  background: white;

  &:hover {
    color: #1a1d22;
    background: darken($color: #fff, $amount: 10);
  }
}

.btn-blue {
  padding: 0.1rem 1rem !important;
  background: #512da8;
  color: white;

  &:hover {
    background: darken($color: #512da8, $amount: 10);
    color: white;
  }
}

.card-table table tbody tr {
  // background-color: black;
  outline: none;
  border-radius: 3px;
  // -webkit-box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 1);
  // -moz-box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 1);
  // box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 1);
  // height: 60px !important;
}

.card-table table tbody tr td {
  border: transparent;
  border-radius: 2px;
  vertical-align: middle;
}

.card-table table {
  border-collapse: separate !important;
  border-spacing: 0 10px;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-img-sm {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: contain;
}

.profile-img-md {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-img-bg {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}

.multicolortext {
  background-image: linear-gradient(
    to left,
    #fcd577,
    #f27679,
    #673ab7,
    #de3c7e,
    #f06879,
    #c23484,
    #512da8
  );
  -webkit-background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  font-family: "proxima-bold";
}

.click {
  cursor: pointer;
}

select {
  padding: 9px !important;
}

.hr-wrapper {
  .workers-card {
    background: white;
    min-height: 180px;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
  }

  .users-card {
    background: #512da8;
    min-height: 180px;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
  }
}

.worker-images {
  img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    cursor: pointer;
  }
}
.each-professionals {
  border: none;
  -webkit-box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
  -moz-box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
  box-shadow: 0px 3px 15px -3px rgba(205, 205, 205, 1);
}

.file-picker {
  #fileUpload {
    display: none;
  }
  .picker {
    cursor: pointer;
  }
}

.img-upload {
  border-radius: 7px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  #spinner {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
