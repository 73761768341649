@font-face {
  font-family: "Radikal-bd";
  src: url("./assets/font/Radikal-Bold.ttf");
}
@font-face {
  font-family: "Alliance-ExtraBold";
  src: url("./assets/font/AllianceExtraBold.ttf");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("./assets/font/Ubuntu-Light.ttf");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("./assets/font/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: "Radikal-rg";
  src: url("./assets/font/Radikal-Regular.ttf");
}
@font-face {
  font-family: "Gotham-rg";
  src: url("./assets/font/GothaProReg.otf");
}
@font-face {
  font-family: "SF-mono";
  src: url("./assets/font/sf-mono-cufonfonts-webfont/SFMonoRegular.woff");
}

:root {
  --primaryColor: #eb5424;
  --altPrimaryColor: #050d21;
  --fontRadikalBold: "Radikal-bd";
  --fontRadikal: "Radikal-rg";
  --fontAllianceExtra: "Alliance-ExtraBold";
  --fontUbuntuLT: "Ubuntu-Light";
  --fontUbuntuRG: "Ubuntu-Regular";
}

*body {
  padding: 0;
  margin: 0;
}
.project-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Radikal-rg", sans-serif;
}

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
.p-r {
  position: relative !important;
}

.btn-orange {
  background: rgb(235, 84, 36) !important;
  color: #fff !important;
  border-radius: 20px !important;
  height: 40px;
  padding: 0.5rem 2rem !important;
}
.btn-alt {
  background: #37bbc6 !important;
  color: #fff !important;
  border-radius: 20px !important;
  min-height: 40px;
  padding: 0.5rem 2rem !important;
}
.btn-blue {
  background: #288acb !important;
  color: #fff !important;
  border-radius: 20px !important;
  min-height: 40px;
  padding: 0.5rem 2rem !important;
}
@media (max-width: 993px) {
  .sm-dnone {
    display: none !important;
  }
}
a {
  &:hover {
    text-decoration: none;
  }
}
.text-inherit {
  color: inherit;
}
.font50 {
  font-size: 50px !important;
}
.font12 {
  font-size: 12px !important;
}
.font15White {
  font-size: 15px !important;
  color: #fff;
}
.font15 {
  font-size: 15px !important;
}
.font18 {
  font-size: 18px !important;
}
.font48 {
  font-size: 48px !important;
}
.font20 {
  font-size: 20px !important;
}
.font25 {
  font-size: 25px !important;
}

.font32 {
  font-size: 32px !important;
}
.box-shadow {
  box-shadow: 0px 5px 9px -5px rgba(0, 0, 0, 0.05);
}
.br-8 {
  border-radius: 8px;
}
.hidden {
  overflow: hidden;
}
.click {
  cursor: pointer;
}
footer {
  margin-top: auto;
}
.font-bold {
  font-weight: 600;
  font-family: "Radikal-bd";
}
.font-light {
  font-weight: lighter !important;
}
footer {
  background: var(--altPrimaryColor);
  // background-color: ;
  padding: 2.5rem;
  // background-size: cover;
  // justify-content: center;
  // display: flex;
  // flex-direction: column;
  // color: #a6a8a8;
  // h4 {
  //   font-size: 25px;
  //   font-weight: bold;
  // }
  // h3 {
  //   font-weight: bold;
  //   font-size: 30px;
  // }
  // p {
  //   color: #fff;
  //   font-size: 14px;
  // }
  p {
    font-size: 1.7vh;
    // line-height: 3rem;
    // letter-spacing: 1px;
    color: #8193b2;
  }
  img {
    height: 50px;
    object-fit: contain;
    filter: brightness(50);
  }
  .iconify {
    height: 2.3vh;
    width: 2.3vh;
    color: #8193b2;
  }
  a {
    color: #8193b2;
    font-size: 1.5vh;
    transition: 0.3s ease all;
    &:active,
    &:hover {
      color: #fff;
    }
  }
  h6 {
    color: white;
  }
  hr.solid {
    border-top: 1px solid #8193b2;
  }
}
.my-hr {
  // border-top: 1px solid #8193b2;
  width: 90%;
  margin-top: 0;
  margin-bottom: 12px;
  height: 0px;
  background: rgba(0, 0, 0, 0);
}
.body-container {
  @media (min-width: 1400px) {
    max-width: 1400px;
  }
  @media (min-width: 1700px) {
    max-width: 1500px;
  }
  @media (min-width: 1800px) {
    max-width: 1600px;
  }
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1000px) {
    padding: 0;
  }
}
.navbar {
  @media (max-width: 992px) {
    display: none;
  }
  &.fixed-top {
    background: white;

    .navlink {
      color: var(--altPrimaryColor);

      .signup {
        color: var(--altPrimaryColor);

        &:hover {
          border: 1px solid rgb(36, 36, 36);
          // color: rgb(36, 36, 36);
        }
      }
    }
    .nav-logo {
      a {
        color: wheat;
        font-size: 20px;
        white-space: nowrap;
      }
      img {
        height: 50px;
        object-fit: contain;
        filter: brightness(0);
      }
    }
  }
  .navlink {
    color: #fff;
    font-size: 13px;
    margin-left: 27px;
    transition: 0.3s ease all;
    .signup {
      font-size: 12px;
      text-transform: uppercase;
      border: 1px solid rgb(205, 210, 212);
      background: none;
      // color: rgb(36, 36, 36);
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
      font-weight: 600;
      padding: 5px 10px 3px;
      height: 2rem;
      border-radius: 4px;
      transition: 0.3s ease all;
      &:hover {
        border: 1px solid rgb(36, 36, 36);
        // color: rgb(36, 36, 36);
      }
    }
    &.login {
      font-size: 12px;
      text-transform: uppercase;
      height: 2rem;
      letter-spacing: 1px;
      font-weight: 600;
      transition: 0.3s ease all;
      &:hover {
        color: rgb(36, 36, 36);
      }
    }
  }
  .nav-logo {
    a {
      color: wheat;
      font-size: 20px;
      white-space: nowrap;
    }
    img {
      height: 50px;
      object-fit: contain;
      filter: brightness(50);
    }
  }
  &.navbarWh {
    .navlink {
      color: var(--altPrimaryColor);

      .signup {
        color: var(--altPrimaryColor);

        &:hover {
          border: 1px solid rgb(36, 36, 36);
          // color: rgb(36, 36, 36);
        }
      }
    }
    .nav-logo {
      a {
        color: wheat;
        font-size: 20px;
        white-space: nowrap;
      }
      img {
        height: 50px;
        object-fit: contain;
        filter: brightness(0);
      }
    }
  }
}

.main-wrapper {
  header {
    background: rgb(6, 44, 107);
    background: linear-gradient(
      108deg,
      rgba(6, 44, 107, 1) 0%,
      rgba(5, 24, 58, 1) 22%,
      rgba(4, 17, 41, 1) 46%,
      rgba(26, 22, 51, 1) 77%,
      rgba(68, 31, 71, 1) 100%
    );
    min-height: 90vh;
    justify-content: flex-end;
    background-position-x: 100%;
    border-radius: 0 0 0rem 0rem;
    .main-head {
      padding-top: 10vh;
      @media (max-width: 770px) {
        padding-top: 13vh;
      }
    }
    p {
      color: #f6f6f6;
    }
    .header-img {
      width: 11.8rem;
      border-radius: 17px;
    }
    .header-text {
      color: rgb(255, 255, 255);
      font-size: 6.5vh;
      line-height: 1.3;
      margin-bottom: 2rem;
      margin-top: 7rem;
      font-family: "Radikal-bd";
      font-weight: bold;

      .solnary {
        border-bottom: 1px solid #eb5424;
      }
      .skill {
        border-bottom: 1px solid #5555f5;
      }
      .solnary,
      .skill {
        // border-bottom: 1px solid #a6a8a8;
        // border-radius: 35px;
        padding: 0.4rem 1rem;
        transition: all ease 0.5s;
        &:hover {
          //   border: 1px solid transparent;
          border-bottom: 1px solid transparent;
        }
      }
      @media (max-width: 800px) {
        .skill,
        .solnary {
          padding: 0.2rem 0.7rem;
        }
      }
    }
    .container-input {
      position: relative;
      @media (min-width: 1200px) {
        margin-top: 50px;
      }
      @media (max-width: 989px) {
        margin-top: 50px;
      }
      @media (max-width: 800px) {
      }
      @media (max-width: 500px) {
        margin-top: 20px;
      }
    }

    .sub-actions {
      // justify-content: center;
      // align-items: center;
      .btn {
        background: var(--primaryColor);
        color: white;
        border-radius: 1.5rem;
        font-size: 1.8vh;
        padding: 1.5vh 1vw 1vh 1vw;
        font-weight: bold;
        transition: all ease 0.5s;

        // white-space: nowrap;
        @media (min-width: 1200px) {
        }
        @media (max-width: 989px) {
        }
        @media (max-width: 800px) {
        }
        @media (max-width: 500px) {
        }
        &:hover {
          border: 2px solid var(--primaryColor);
          background: transparent;
          color: var(--primaryColor);
        }
      }
      .intro {
        border-radius: 1.5rem;
        padding: 1vh 2.5vw 1vh 2.5vw;
        transition: all ease 0.5s;
        &:hover {
          border: 1.9px solid white;
        }
      }
      img {
        height: 20px;
        width: 20px;
        object-fit: contain;
        // filter: brightness(50%);
        filter: brightness(50);
      }
      p {
        margin-left: 1px;
        font-size: 18px;
        color: rgb(255, 255, 255);
      }
    }

    .get-started-input {
      background: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 0.6rem 1rem 0.6rem 2rem;
      border-radius: 2.4rem;
      width: 100%;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      transition: all ease 0.5s;
      &:hover {
        border: 1px solid #a6a8a8;
      }
      @media (min-width: 980px) {
        width: 80%;
      }
      .btn {
        background: #181648;
        color: white;
        border-radius: 1.4rem;
        padding: 0.7rem 1rem 0.7rem 1rem;
        font-weight: bold;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        @media (min-width: 980px) and(max-width:989px) {
          padding: 0.7rem;
        }
        @media (max-width: 800px) {
          padding: 0.5rem;
          width: 69%;
          margin-left: auto;
          font-size: 12px;
          .arrow {
            display: none;
          }
        }
        @media (max-width: 500px) {
          margin-left: auto;
          width: 78%;
        }
        @media (min-width: 1200px) {
          margin-left: auto;
          width: 78%;
        }
      }
      label {
        color: gray;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
      }
      input {
        width: 100%;
        border: none;
        &:focus {
          border: none;
          outline: none;
        }
        &::placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        :-ms-input-placeholder {
          color: black !important;
          font-size: 18px !important;
        }
      }
      span {
        font-size: 20px;
        color: #7188a4;
      }
      .w-60 {
        width: 60%;
      }
      .w-40 {
        width: 40%;
      }
    }
  }

  .brands {
    display: flex;
    // justify-content: space-between;
    img {
      height: 23px;
      width: 23px;
      margin-right: 7px;
      object-fit: contain;
    }
    p {
      color: #8193b2;
      font-family: var(--fontRadikal);
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}

.scrollBtn {
  position: fixed;
  right: 20px;
  bottom: 70px;
  // bottom: 40px;
  height: 40px;
  width: 40px;
  font-size: 3rem;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  border-radius: 16px;
  transition: 0.5s ease all;

  .fa-icon {
    background: var(--altPrimaryColor);
    padding: 12px;
    border-radius: 16px;
    color: white;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
      -5px -5px 35px rgba(254, 214, 124, 0.1);
  }
}

.faq-wrapper {
  .faq-header {
    margin-top: 9vh;
  }
  h2 {
    font-family: var(--fontAllianceExtra);
  }
  h4 {
    font-family: var(--fontAllianceExtra);
  }
  p {
    font-size: 1.6vh;
  }
}

.signup-wrapper {
  .input-icon {
    position: absolute;
    right: 18px;
    top: 11px;
    color: #9198a2;
    font-size: 18px;
    &.outline {
      color: #9198a2;
      -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #9198a2;
      font-size: 18px;
    }
  }
  form {
    min-height: 450px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  }
  .form-wrapper {
    min-height: 100vh;
    padding-top: 10vh;
  }
  .common-input {
    border: 0;
    border-bottom: 1px solid rgba(145, 152, 162, 0.5);
    padding: 1.375rem 0.75rem !important;
    border-radius: 0;
  }
}

.stripe-checkout-wrapper {
  form {
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    min-height: auto !important;
    background: transparent !important;
  }
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
}

.signIn-wrapper {
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
  .sign-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .logo-wrapper {
    .login-logo {
      width: 200px;
      height: 100px;
      object-fit: contain;
    }
  }
  footer {
    p,
    a {
      font-size: 11px;
      color: #b9b9b9 !important;
      font-family: "Museo-bd";
    }
  }
  form {
    background-color: white;
    border-radius: 4px;
    min-height: 470px;
    a:hover {
      text-decoration: none;
    }
    &.signupForm {
      padding: 3rem;
      @media (max-width: 770px) {
        padding: 3rem 1.5rem;
      }
    }
  }
}

@media (max-width: 770px) {
  .font50 {
    font-size: 35px !important;
  }
  .font48 {
    font-size: 30px !important;
  }
  .font18 {
    font-size: 16px !important;
  }
}

.photoshopped-boxes {
  padding: 0 0 15vh 0;
}
.slider-section {
  padding-top: 5vh;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  .item {
    animation: moveSlideshow 11s linear infinite;
  }
  @keyframes moveSlideshow {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(-66.6666%);
    }
  }
  img {
    min-width: 150px;
    max-width: 190px;
    max-height: 60px;
    margin-right: 50px;
    object-fit: contain;
  }
}

.test-header-wrapper {
  height: 700px;
  background-color: red; /* For browsers that do not support gradients */
  background-image: linear-gradient(to bottom right, #050e23, #462047);
}

.marquee {
  width: 100%;
  margin: 2vh auto 0vh auto;
  // line-height: 50px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  img {
    min-width: 150px;
    max-width: 190px;
    max-height: 60px;
    margin-right: 50px;
    object-fit: contain;
  }
}
.marquee div {
  display: inline-block;
  padding-left: 20%;
  animation: marquee 120s linear infinite;
}
.marquee div span {
  padding-left: 15px;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.br-10 {
  border-radius: 10px;
}

.inner-about {
  background-color: var(--altPrimaryColor);
  padding: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
  border-radius: 45px;
}

#about {
  color: rgb(30, 33, 42);
  padding-top: 5vh;
  padding-bottom: 9vh;
  // background-image: url("./assets/img/bg_web.jpg");
  // background-repeat: repeat-y;

  // background-color: #e5e5f7;
  // opacity: 0.8;
  background-image: radial-gradient(
    var(--altPrimaryColor) 0.5px,
    #ffffffff 0.5px
  );
  background-size: 10px 10px;

  .title {
    font-size: 7vh;
    font-weight: bold;
    line-height: 4rem;
  }
  h3 {
    // text-transform: uppercase;
    background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
      size: 14vw;
      family: var(--fontAllianceExtra);
    }
  }
  p {
    font-size: 28px;
    line-height: 3rem;
    letter-spacing: 1px;
    color: #8193b2;
  }
  h6 {
    font-size: 15px;
    color: #75859f;

    line-height: 1.3rem;
    letter-spacing: 1.2px;
  }
  @media (max-width: 1200px) {
    .title {
      line-height: 3.5rem;
      font-size: 45px;
    }
    p {
      line-height: 2rem;
      font-size: 20px;
      color: #8193b2;
    }
  }
  @media (max-width: 800px) {
    .title {
      line-height: 3rem;
      font-size: 35px;
    }
    p {
      line-height: 2rem;
      font-size: 20px;
      color: #8193b2;
    }
  }
  .ruler {
    height: 1px;
    // width: 100%;
    background-color: #8193b2;
  }
}

#portfolio {
  background-color: #a9d7d8;
  padding: 2rem 2rem;
  .title {
    font-size: 5vh;
    font-weight: bold;
    line-height: 4rem;
    text-align: center;
  }
  h3 {
    // text-transform: uppercase;
    background: linear-gradient(to right, #c24fac 0%, #5575e3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
      size: 14vw;
      family: var(--fontAllianceExtra);
    }
  }
  // .container {
  //   width: 100%;
  //   display: block;
  //   margin: 0 auto;
  // }
  .masonry {
    column-count: 2;
    column-gap: 0px;
  }
  @media (max-width: 700px) {
    .masonry {
      column-count: 1;
    }
  }
  @media (min-width: 768px) {
    .masonry {
      column-count: 2;
    }
  }
  @media (min-width: 992px) {
    .masonry {
      column-count: 2;
    }
  }
  @media (min-width: 1199px) {
    .masonry {
      column-count: 2;
    }
  }

  .masonry {
    .brick {
      box-sizing: border-box;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      counter-increment: brick-counter;
      border-radius: 16px;

      position: relative;
      width: 90%;
      margin: auto;
      margin-bottom: 1.5vh;
      overflow: hidden;

      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
    }

    .brick-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .brick .brick-overlay {
      background: rgba(106, 112, 218, 0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
    }

    .brick:hover .brick-overlay {
      opacity: 1;
    }

    .content-details {
      position: absolute;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      width: 100%;
      top: 50%;
      left: 50%;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s ease-in-out 0s;
      -moz-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
    .brick:hover .content-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }
    .content-details h2 {
      color: #fff;
      font-weight: 500;
      // letter-spacing: 0.15em;
      margin-bottom: 0.5em;
      // text-transform: uppercase;
    }
    .content-details p {
      color: #fff;
      font-size: 0.8em;
    }

    .fadeIn-bottom {
      top: 80%;
    }
  }
}

.shadowCard {
  width: 60%;
  height: 100px;
  background: "#0D172D";
  border: 1px solid #8193b2;
  // border-width: 1px;
  border-color: rgba(160, 160, 255, 0.2);
  border-radius: 14px;
  position: absolute;
  box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
    -5px -5px 35px rgba(254, 214, 124, 0.1);
  left: 50%;
  transform: translate(-50%, 0);
  p {
    font-size: 14px;
    padding: 0px 10px 5px 10px;
    line-height: 3rem;
    letter-spacing: 1px;
    color: #8193b2;
  }
}

.shadowCard2 {
  width: 69%;
  height: 100px;
  margin-top: 35px;
  // background-color: "red";
  background: var(--altPrimaryColor);
  border: 1px solid #8193b2;
  // border-width: 1px;
  border-color: rgba(160, 160, 255, 0.2);
  border-radius: 14px;
  position: absolute;
  box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
    -5px -5px 35px rgba(254, 214, 124, 0.1);
  left: 50%;
  transform: translate(-50%, 0);
  p {
    font-size: 14px;
    padding: 0px 10px 5px 10px;
    line-height: 3rem;
    letter-spacing: 1px;
    color: #8193b2;
  }
}

#tech {
  background-color: var(--altPrimaryColor);
  padding: 4rem;
  padding-top: 5rem;
  h6 {
    font-size: 15px;
    color: #75859f;
    line-height: 1.3rem;
    letter-spacing: 1.2px;
  }

  .title {
    background: linear-gradient(to right, #ff8272 0%, #fed67c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
      size: 20px;
      family: var(--fontAllianceExtra);
    }
  }

  .font-tech,
  li {
    font-size: 14px;
    line-height: 1.5rem;
    letter-spacing: 1px;
    color: #8193b2;
  }

  .card {
    width: 80%;
    margin-top: 75px;
    background: var(--altPrimaryColor);
    border-radius: 14px;
    top: 0px;
    transition: 0.9s all ease-in-out;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.2),
      -5px -5px 35px rgba(254, 214, 124, 0.3);
    &:hover {
      top: -100px;
    }
    p {
      font-size: 14px;
      padding: 0px 10px 5px 10px;
      line-height: 3rem;
      letter-spacing: 1px;
      color: #8193b2;
    }
    h3 {
      background: linear-gradient(to right, #ff8272 0%, #fed67c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header {
      padding: 14px 20px 10px 14px;
      .ruler {
        height: 1px;
        background-color: #8193b2;
      }
    }
    .content {
      padding: 0px 16px 24px 16px;
      .info {
        font-size: 14px;
        line-height: 1rem;
        letter-spacing: 1px;
        color: #8193b2;
      }
    }
  }
}

.tech-img {
  height: 250px;
  object-fit: cover;
}

#services {
  background: #000921;
  min-height: 90vh;
  // margin: 10vh 0;
  padding: 5vh 0;
  .container {
    @media (min-width: 576px) {
      max-width: 640px;
    }
    @media (min-width: 768px) {
      max-width: 820px;
    }
    @media (min-width: 992px) {
      max-width: 1060px;
    }
    @media (min-width: 1200px) {
      max-width: 1440px;
    }
  }

  .title,
  h3 {
    background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
    // background: linear-gradient(to right, #ff8272 0%, #fed67c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
      size: 30px;
      family: var(--fontAllianceExtra);
    }
  }

  .btn-contact {
    display: flex;
    // min-height: 50px;
    justify-content: center;
    align-items: center;
    margin-right: 10vw;
    border-radius: 12px;
    padding: 2.5vh 3vh 2.5vh 3vh;
  }
  p {
    font-size: 28px;
    color: rgb(30, 33, 42);
  }
  .divider-section {
    display: grid;
    // grid-template-columns: 16px 1fr 1fr 1fr 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 30px;
    overflow: scroll hidden;
    white-space: nowrap;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 900px) {
      // grid-template-columns: 70px 1fr 1fr 1fr 1fr 70px;
      padding-bottom: 40px;
    }
    .fake-box {
      height: 7.7rem;
      background: linear-gradient(
          to right,
          rgb(238, 93, 74) 90%,
          rgb(238, 93, 74)
        )
        left bottom / 100% 0.3rem no-repeat;
    }
    .box {
      min-width: 26.3rem;
      border-width: 0px 0px 0px 0.05rem;
      background: linear-gradient(
          rgb(201, 202, 206) 0%,
          rgb(239, 240, 242) 100%
        )
        0% 0% / 0.05rem 100% no-repeat;
      padding: 3.2rem 0px;

      @media (max-width: 900px) {
        background: none;
      }
      &.bg-none {
        background: none;
      }
      p {
        font-family: "Radikal-bd", sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.6px;
        padding: 0px 16px 20px;
      }
      .content {
        display: flex;
        flex-flow: row wrap;
        padding: 0px 1.6rem;
        .card {
          font-family: Inter, var(--fontRadikal);
          display: inline-block;
          // color: rgb(30, 33, 42);
          color: #8193b2;
          font-size: 10px;
          line-height: 2rem;
          // background: rgb(255, 255, 255);
          padding: 0.7px 16px;
          border-radius: 30px;
          margin: 0px 4px 4px 0px;
          // background-color: "red";
          background: var(--altPrimaryColor);
          border: 1px solid #8193b2;
          // border-width: 1px;
          border-color: rgba(160, 160, 255, 0.2);
          border-radius: 14px;
          position: absolute;
          box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.1),
            -5px -5px 35px rgba(254, 214, 124, 0.1);
          // white-space: break-spaces;
          // border-style: solid;
          // border-width: 1px;

          position: relative;
          cursor: pointer;
          transition: ease-out 0.5s;
          -webkit-transition: ease-out 0.5s;
          -moz-transition: ease-out 0.5s;
        }
      }
    }
    .first {
      p {
        font-size: 12px;
        color: rgb(238, 93, 74);
        background: linear-gradient(
            to right,
            rgb(238, 93, 74) 90%,
            rgb(236, 88, 215)
          )
          left bottom / 100% 0.3rem no-repeat;
      }
    }
    .second {
      p {
        color: rgb(236, 88, 215);
        background: linear-gradient(
            to right,
            rgb(236, 88, 215) 90%,
            rgb(97, 98, 246)
          )
          left bottom / 100% 0.3rem no-repeat;
      }
    }
    .third {
      p {
        color: rgb(97, 98, 246);
        background: linear-gradient(
            to right,
            rgb(97, 98, 246) 90%,
            rgb(19, 166, 136)
          )
          left bottom / 100% 0.3rem no-repeat;
      }
    }
  }
  .feature-progress {
    width: 300px;
    p {
      margin: 0px 18px 0px 0px;
      font-family: "Radikal-bd", sans-serif;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: rgb(101, 103, 110);
    }
    .bar-bg {
      width: 160px;
      height: 3px;
      background: rgb(201, 202, 206);
      position: relative;
      .bar {
        height: 100%;
        position: absolute;
        width: 91%;
        transition: all 0.5s ease-in-out 0s;
        &-1 {
          background: linear-gradient(
            to left,
            rgb(91, 197, 161),
            rgb(102, 196, 231)
          );
        }
        &-2 {
          background: linear-gradient(
            to left,
            rgb(236, 88, 215),
            rgb(238, 93, 74)
          );
        }
        &-3 {
          background: linear-gradient(
            to left,
            rgb(74, 134, 247),
            rgb(97, 98, 246)
          );
        }
      }
    }
  }
}

.alt-font {
  font-family: var(--fontAllianceExtra);
}
#plans {
  background: #03142a;
  min-height: 80vh;
  // border-radius: 29px;
  padding: 4vh 0;
  color: #9198a2;
  h3 {
    font-size: 40px;
  }
  p {
    font-size: 19px;
  }
  .plan-txt {
    font-size: 14px;
    padding: 0px 10px 5px 10px;
    line-height: 3rem;
    letter-spacing: 1px;
    color: #8193b2;
  }
  .price-wrapper {
    display: flex;
    flex-direction: column;
    background: transparent;
    transition: ease all 0.3s;
    border-radius: 12px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    @media (min-width: 400px) {
      min-height: 647px;
    }
    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.24);
    }
    .text-light {
      color: #ffffff94;
    }

    h5 {
      font-size: 18px;
    }
    .price {
      display: flex;
      .tag {
        font-size: 40px;
        font-weight: bolder;
        color: #d0e2fb;
        margin-right: 5px;
      }
      .tagC {
        font-size: 30px;
        font-weight: bolder;
        color: #d0e2fb;
        margin-right: 5px;
      }
      .duration {
        margin: auto 0;
      }
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 0.7rem;
        display: flex;
        &.view-more {
          color: #eb5424;
          cursor: pointer;
          font-weight: bold;
          margin-left: 22px;
        }
      }
    }
    .more-content {
      display: none;
      &.show {
        display: block;
      }
    }
    .btn-plan {
      background: transparent;
      color: #eb5424;
      border: #eb5424 solid 1px;
      border-radius: 7px;
      font-weight: bold;
      min-height: 45px;
      text-align: center;
      width: 100%;
      transition: all ease 0.5s;
      &:hover {
        background: #eb5424;
        color: white;
      }
    }
    &.bg-color {
      background-color: #eb5424;
      color: #fff;
      .text-light {
        color: #ffffff94;
      }
      .tag {
        color: white;
      }
      .btn-plan {
        background: #ffffffba;
        &:hover {
          background: #ffffff9e;
          color: #eb5424;
        }
      }
      ul {
        li {
          &.view-more {
            color: #d0e2fb;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .col-6 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 1200px) and(min-width:992px) {
    .col-lg-3 {
      -ms-flex: 0 0 32%;
      flex: 0 0 32%;
      max-width: 32%;
    }
  }
  .card {
    display: flex;
    width: 90%;
    margin-top: 75px;
    background: var(--altPrimaryColor);
    border-radius: 14px;
    top: 0px;
    transition: 0.3s all ease-in-out;
    box-shadow: 5px 5px 20px rgba(255, 130, 114, 0.2),
      -5px -5px 35px rgba(254, 214, 124, 0.3);
    &:hover {
      // top: -80px;

      // margin-top: -10px;
    }
    p {
      font-size: 14px;
      padding: 0px 10px 5px 10px;
      line-height: 3rem;
      letter-spacing: 1px;
      color: #8193b2;
    }
    .header1 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #ff8272 0%, #fed67c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header2 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #c24fac 0%, #5575e3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header3 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header4 {
      padding: 14px 20px 10px 14px;
      background: linear-gradient(to right, #330867 0%, #ff8272 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font: {
        size: 20px;
        family: var(--fontAllianceExtra);
      }
    }
    .header {
      padding: 14px 20px 10px 14px;
      .ruler {
        height: 1px;
        background-color: #8193b2;
      }
    }
    .content {
      padding: 0px 16px 24px 16px;
      .info {
        font-size: 14px;
        line-height: 1rem;
        letter-spacing: 1px;
        color: #8193b2;
      }
    }

    &__button {
      padding: 10px;
      border-radius: 50px;
      background: #ff8272;
      color: white;
      font-weight: bold;
      cursor: pointer;
      border: none;
      // margin: 20px auto;
    }

    .btn-price {
      background: var(--primaryColor) !important;
      color: #fff !important;
      border-radius: 24px !important;
      padding: 0.8vh 2.5vh 0.7vh 2.5vh !important;
      margin-top: 20px;
      font-size: 1.8vh;
    }
  }
}
.status_completed {
  background: #31d067;
  border-radius: 10px;
  height: 18px;
  width: 73px;
}
.status_in_progress {
  background: #ef943b;
  border-radius: 10px;
  height: 18px;
  width: 73px;
}
.status_not_started {
  background: #968e8e;
  border-radius: 10px;
  height: 18px;
  width: 73px;
  white-space: nowrap;
}
.status_2 {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.high {
  background: #e5364e;
  border-radius: 10px;
  width: 46px;
  height: 18px;
}

.medium {
  background: #ef943b;
  border-radius: 10px;
  width: 61px;
  height: 18px;
}

.low {
  background: #31d067;
  border-radius: 10px;
  width: 44px;
  height: 18px;
}
.choose-title {
  margin-top: 15vh;
  font-size: 18px;
  font-weight: bold;
  color: #484848;
  text-transform: capitalize;
}
.choose-card {
  margin: 0 auto;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  border-radius: 14px;
  max-width: 70%;
  // height: 155px;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 21px;
  padding: 40px;
  // border-width: 1px;
  // border: 1px solid #f5ece3;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #ef943b;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card3 {
  display: block;
  top: 0px;
  position: relative;
  // max-width: 262px;
  height: 160px;
  background-color: #ef943b;
  border-radius: 12px;
  border-width: 1px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f5ece3;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  // max-width: 262px;
  height: 160px;
  background-color: #fff;
  border-radius: 12px;
  border-width: 1px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f5ece3;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #f7e1ca;
    background-color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #ef943b;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}

.sm-play-btn {
  display: none;
}
@media (max-width: 767px) {
  .lottieAnim {
    display: none;
  }
  .sm-play-btn {
    display: inline-block;
    width: 43px;
  }
}

.login-form .form-control {
  width: 100%;
  min-height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 9px;
  font-size: 14px;
}
.login-form .form-area {
  min-height: 150px;
}

.btnSol {
  // box-shadow: 2px 2px 2px 2px rgba(159, 162, 176, 0.3);
  padding: 0rem 2.8rem !important;
  color: #fff;
  margin: 0 auto;
  font-size: 16px;
  min-height: 40px;
  background: #eb5424;
  border-radius: 12px;
  border: none;
  outline: none;
  *:focus {
    outline: 0 !important;
  }
  &:hover {
    transform: translateY(-2px);
    // transition: transform 0.15s ease-out;
  }
}
.receipt-wrapper {
  position: relative;
  background-color: white;
  padding: 60px 50px 20px;
  overflow: hidden;

  table {
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    color: #484848;
    text-align: left;
    padding: 8px;
  }
  th {
    font-weight: bold;
    border-bottom: 1px solid #484848;
  }
  p {
    margin-top: 0;
    margin-bottom: 8px;
  }
  .badge {
    width: 65px;
    height: 22px;
    text-transform: capitalize;
    padding: 0.25rem;
  }
  .badge-partial {
    background: #ffedca;
    border: 0.5px solid #bd8311;
    border-radius: 3px;
    color: #bd8311;
  }
  .badge-posted {
    background: #ffedca;
    border: 0.5px solid #bd8311;
    border-radius: 3px;
    color: #bd8311;
  }
  .badge-paid {
    background: #ebffef;
    border: 0.5px solid #14922d;
    border-radius: 3px;
    color: #14922d;
  }
  .badge-sent {
    background: rgba(16, 112, 183, 0.1);
    border: 0.5px solid #1070b7;
    border-radius: 3px;
    color: #1070b7;
  }
  .badge-draft {
    background: #f3f3f3;
    border: 0.5px solid #999999;
    border-radius: 3px;
    color: #999999;
  }
  .badge-overdue {
    background: rgba(169, 9, 41, 0.1);
    border: 0.5px solid #a90929;
    box-sizing: border-box;
    border-radius: 3px;
    color: #a90929;
  }

  .stamp .badge {
    position: absolute;
    top: 16px;
    left: -39px;
    transform: rotate(-38deg);
    border: 0;
    border-radius: 0;
    width: 119px;
    height: 24px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
}
.btn-download {
  background: rgba(16, 112, 183, 0.1);
  border: 0.5px solid #1070b7;
  color: #1070b7;
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 10px 3px;
  border-radius: 4px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  height: 28px;
  width: 28px;
}

.project-details-wrapper {
  background: #f3f4f5;

  .headerLabel {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    font-family: var(--fontUbuntuRG);
  }
  .label {
    color: rgba(0, 0, 0, 0.8);
    font-family: var(--fontUbuntuRG);
    font-size: 12px;
    margin-bottom: 0px;
  }
  .value {
    color: var(--altPrimaryColor);
    line-height: 1;
    font-family: var(--fontUbuntuLT);
    margin-top: 0px;
    font-size: 14px;
  }

  h3 {
    font-family: var(--fontAllianceExtra);
    color: var(--altPrimaryColor);
    font-size: 24px;
  }
}
